<template>
  <div class="section_main">
    <div class="section_cont swiper_cont">
      <div class="c-w1200 swiper_text">
        <div class="swiper_title">数字化+智慧化</div>
        <div class="swiper_title">优数科技助力安保行业提升服务能力</div>
      </div>
    </div>

    <div class="section_cont">
      <div class="c-w1200">
        <h3>优数安保通</h3>

        <div class="desc_cont">
          <p>
            安保通平台主要针对安保行业用人难、招人难问题，此平台在全国各地布局生态合作伙伴，代理推荐人才，招聘平台定向合作、校企合作推送等方式，解决招人难问题，打通C端道B端信息差，返佣难问题。
          </p>
          <p>
            各企业通过安保易SaaS平台发布招聘岗位，招聘数据自动同步道安保通平台，全国渠道为此平台推荐人员，解决岗位用人需求，提高平台知名度和认可度。
          </p>
        </div>
      </div>
    </div>

    <div class="section_cont">
      <div class="c-w1200">
        <div class="andUp_cont">
          <div
            class="and-up"
            v-for="(item, index) in andUpList"
            :key="'andUp_' + index"
          >
            <h4>{{ item.title }}</h4>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section_cont">
      <div class="c-w1200 recommend_cont">
        <div class="recommend_desc">
          <p>
            安保通平台已上线微信小程序端，微信搜索“优数安保通”小程序或扫描右侧二维码即可，手机客户端及PC客户端正在开发中，敬请期待！
          </p>
        </div>
        <ul>
          <li>
            <img src="@/assets/img/abt_Applets.jpg" alt="" />
            <p>优数安保通小程序</p>
          </li>
          <li>
            <img src="@/assets/img/public.jpg" alt="" />
            <p>优数合一公众号</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="section_cont">
      <div class="c-w1200 abt_introduce">
        <dl>
          <dt><img src="@/assets/img/abt_img.png" alt="" /></dt>
          <dd>
            安保通平台已上线微信小程序端，微信搜索“优数安保通”小程序或扫描右侧二维码即可，手机客户端及PC客户端正在开发中，敬请期待！
          </dd>
        </dl>
      </div>
    </div>

    <fixedDialog></fixedDialog>
  </div>
</template>
  
  <script>
import fixedDialog from "@/components/fixedDialog.vue";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    fixedDialog
  },
  data() {
    return {
      andUpList: [
        {
          title: "10万人+",
          desc: "累计用户量",
        },
        {
          title: "100万次+",
          desc: "累计成交量",
        },
        {
          title: "6万人+",
          desc: "累计输送人才",
        },
        {
          title: "10万元+",
          desc: "累计节省企业成本",
        },
      ],
    };
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
@import url("../assets/css/common.css");
/* 基础描述 */
.swiper_cont {
  background: #3f72fd !important;
}
.swiper_text {
  padding-top: 156px;
  padding-bottom: 104px;
}
.swiper_title {
  font-size: 36px;
  color: #fff;
}
.swiper_title:nth-child(1) {
  padding-bottom: 20px;
}
.section_cont h3 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 25px;
}
.section_cont h6 {
  font-size: 16px;
  text-align: center;
}
.desc_cont {
  padding-top: 35px;
  padding-left: 80px;
  padding-right: 80px;
}
.desc_cont p {
  font-size: 18px;
  text-align: left;
  line-height: 35px;
}

.andUp_cont {
  padding: 90px 80px 180px 80px;
  overflow: hidden;
}
.and-up {
  width: 25%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.and-up img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.and-up h4 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;
}
.and-up p {
  font-size: 18px;
  color: #555555;
  line-height: 25px;
}
.recommend_cont,
.recommend_cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommend_cont {
  padding: 0 80px 160px 80px;
}
.recommend_desc {
  margin-right: 105px;
  line-height: 30px;
  color: #7f7f7f;
}

.recommend_cont ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 55px;
}
.recommend_cont ul li img {
  width: 80px;
  margin-bottom: 10px;
}
.recommend_cont ul li p {
  color: #555555;
  font-size: 12px;
  white-space: nowrap;
}
.abt_introduce {
  padding: 0 80px 240px 80px;
}
.abt_introduce dl {
  display: flex;
  justify-content: space-between;
}
.abt_introduce dl dt {
  width: 40%;
  margin-right: 5%;
}

.abt_introduce dl dt img {
  width: 100%;
}
.abt_introduce dl dd {
  width: 60%;
  color: #7f7f7f;
  font-size: 18px;
  line-height: 30px;
}
</style>