<template>
  <div id="app">
    <Headers @trilFome="iShowTryDialog"></Headers>
    <section>
      <router-view></router-view>
      <fixedDialog
        :moreDivOffsetTop="moreDivOffsetTop"
        :scrollTop="scrollTop"
        @trilFome="iShowTryDialog"
      ></fixedDialog>

      <trialFome
        ref="trialFome"
        :visible="tryDialog"
        @updateVisible="closeVisible"
        @submit="onSubmit"
        @touchmove.prevent
        @mousewheel.prevent
      ></trialFome>
    </section>
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "./layouts/headers.vue";
import Footers from "./layouts/footers.vue";
import fixedDialog from "@/components/fixedDialog.vue";
import trialFome from "@/components/trialFome.vue";
import { message } from "ant-design-vue";
import { ProductTrialSave } from "@/apis/productTry.js";
export default {
  name: "App",
  components: {
    Headers,
    Footers,
    fixedDialog,
    trialFome
  },
  data() {
    return {
      tryDialog: false
    }
  },
  methods: {
    iShowTryDialog(data) {
      console.log(data)
      this.tryDialog = data;
    },
    closeVisible(data) {
      this.tryDialog = data.tryDialog;
    },
    onSubmit(data) {
      let formState = JSON.parse(JSON.stringify(data));
      ProductTrialSave({
        company: formState.EnterpriseName,
        user: formState.userName,
        phone: formState.userPhone,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 1) {
          message.success(res.data.message);
        }
      });
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}
</style>
