<template>
  <div class="header">
    <div class="header-cont c-w1200">
      <router-link to="/">
        <img alt="Vue logo" src="@/assets/img/logo.png" />
      </router-link>
      <ul>
        <li>
          <router-link to="/#productCon">产品与服务</router-link>
        </li>
        <li>
          <router-link to="/#customerCon">客户案例</router-link>
        </li>
        <li>
          <router-link to="/#aboutCon">公司介绍</router-link>
        </li>
        <li>
          <router-link to="/#aboutCon">联系我们</router-link>
        </li>
        <li>
          <a-button @click="ontrial">免费试用<RightCircleOutlined size="16" /></a-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { RightCircleOutlined } from "@ant-design/icons-vue";
export default {
  name: "Headers",
  components: {
    RightCircleOutlined,
  },
  methods: {
    ontrial() {
      this.$emit('trilFome', true)
    }
  }
};
</script>

<style scoped>
@import url("../assets/css/common.css");
.header {
  position: absolute;
  left: 0;
  right: 0;
}
.header-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.header-cont img {
  height: 60px;
}
.header-cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-cont ul li {
  list-style: none;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  padding: 20px 40px;
}
.header-cont ul li:last-child {
  padding-right: 0 !important;
}
.header-cont ul li a {
  color: #ffffff;
}

.ant-btn {
  color: #3f72fd !important;
}
</style>
