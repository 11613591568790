<template>
  <div class="footers">
    <div class="c-w1200 footers-cont">
      <dl>
        <dt><img src="@/assets/img/footer-logo.png" alt="" /></dt>
        <dd>
          <p>
            Copyright © 2022 Youshu Technology. All Rights Reserved. 优数科技&nbsp;&nbsp;版权所有
          </p>
          <p>北京优数合一科技有限公司&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021037333号-1</a></p>
        </dd>
      </dl>
      <ul>
        <li>
          <img src="@/assets/img/abt_Applets.jpg" alt="" />
          <p>优数安保通小程序</p>
        </li>
        <li>
          <img src="@/assets/img/public.jpg" alt="" />
          <p>优数合一公众号</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footers",
};
</script>

<style scoped>
@import url("../assets/css/common.css");
.footers {
  background: #fff;
}
.footers-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
}
.footers-cont dl,
.footers-cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footers-cont dl {
  width: 75%;
}
.footers-cont ul {
  width: 20%;
}
.footers-cont dl dt {
  height: 60px;
}
.footers-cont dl dt img {
  height: 100%;
}
.footers-cont dl dd {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8%;
}
.footers-cont dl dd p {
  color: #555555;
  font-size: 14px;
}
.footers-cont ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10%;
}
.footers-cont ul li:last-child {
  margin-right: 0 !important;
}
.footers-cont ul li img {
  width: 80px;
  margin-bottom: 10px;
}
.footers-cont ul li p {
  color: #555555;
  font-size: 12px;
}
</style>