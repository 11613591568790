<template>
  <div class="section_main">
    <div class="section_cont swiper_cont">
      <div class="videoContainer">
        <video
          class="fullscreenVideo"
          id="bgVid"
          playsinline=""
          autoplay=""
          muted=""
          loop=""
        >
          <source src="../assets/img/video.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="c-w1200 swiper_text">
        <div class="swiper_title">数字化+智慧化</div>
        <div class="swiper_title">优数科技助力安保行业提升服务能力</div>
      </div>
    </div>

    <div class="section_cont">
      <div class="c-w1200">
        <h3>数字化+智慧化 助力安保行业提升服务能力</h3>
        <h6>
          以科技创新为支撑，互联网为核心，数据为导向，助力安保行业深远发展
        </h6>

        <div class="desc_cont">
          <p>
            优数科技致力于通过大数据分析挖掘和认知智能技术，推动知识和管理大中小型企业进行数字化转型。
          </p>
          <p>
            整合大数据、区块链、物联网、知识图谱和多模态人工智能技术，将正确的数据推送给决策者，让组织高效运转、加速创新。
          </p>
        </div>
      </div>
    </div>

    <div class="section_cont" id="productCon">
      <div class="c-w1200">
        <h3>优数产品矩阵 全方位业务护航</h3>
        <h6>三大核心产品，提供全流程解决方案</h6>

        <div class="core_cont">
          <dl>
            <dt><img src="@/assets/img/abt-logo.png" alt="" /></dt>
            <dd>
              <h4>优数安保通</h4>
              <h5>
                安保通平台主要针对安保行业用人难、招人难问题，此平台在全国各地布局生态合作伙伴，代理推荐人才，招聘平台定向合作、校企合作推送等方式，解决招人难问题，打通C端道B端信息差，返佣难问题。
              </h5>
              <!-- <div class="core_btn">
                <a-button type="primary" @click="gotoProductDetails(1)"
                  >查看详情</a-button
                >
              </div> -->
            </dd>
          </dl>
          <dl>
            <dt><img src="@/assets/img/aby-logo.png" alt="" /></dt>
            <dd>
              <h4>优数安保易</h4>
              <h5>
                安保易SaaS平台致力于智慧安保/安防、智慧人力等安保领域的软硬件产品研发/生产/销售及运营服务。智慧安保依托最前沿的物联网、云计算及人工智能技术，坚持自主创新，突破了传统安保行业壁垒，打造出了新一代的Ai智慧安保服务平台。
              </h5>
              <!-- <div class="core_btn">
                <a-button type="primary" @click="gotoProductDetails(2)"
                  >查看详情</a-button
                >
              </div> -->
            </dd>
          </dl>
          <dl>
            <dt><img src="@/assets/img/ysy-logo.png" alt="" /></dt>
            <dd>
              <h4>优数云</h4>
              <h5>
                优数云基于华为云底层资源服务，提供行业业务所需计算、存储、网络等服务，高效稳定为企业业务运行提供底层支持服务。优数云特点：易伸缩，可伸缩性是指系统通过对资源的合理调整云应对负载变化的特性，以此来保持性能的一致性。
              </h5>
              <!-- <div class="core_btn">
                <a-button type="primary" @click="gotoProductDetails(3)"
                  >查看详情</a-button
                >
              </div> -->
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="section_cont" id="more">
      <div class="c-w1200">
        <h3>更多服务功能 提供全方位助力</h3>

        <div class="more_cont">
          <ul>
            <li>
              <img src="@/assets/img/img_01.png" alt="" />
              <h4>政企项目招标信息</h4>
            </li>
            <li>
              <img src="@/assets/img/img_02.png" alt="" />
              <h4>专业法律团队服务</h4>
            </li>
            <li>
              <img src="@/assets/img/img_03.png" alt="" />
              <h4>安保行业金融服务</h4>
            </li>
          </ul>
          <h6>更多优质服务，敬请期待~</h6>
        </div>
      </div>
    </div>

    <div class="section_cont" id="customerCon">
      <div class="c-w1200">
        <h3>客户案例</h3>
        <h6>以用户价值为导向，共筑安保价值链</h6>
        <div style="position: relative">
          <div class="case_cont" ref="personWrap" @scroll="scrollEvent($event)">
            <swiper
              class="swiper-container"
              :pagination="pagination"
              :slides-per-view="3"
              :slides-per-group="3"
            >
              <swiper-slide
                class="swiper-slide"
                v-for="(items, ind) in customerList"
                :key="ind"
              >
                <h4>{{ items.name }}</h4>
                <p>
                  {{ items.desc }}
                </p>
                <!-- <a-button
                  type="primary"
                  block
                  ghost
                  @click="gotoDetails(items.id)"
                  >查看详情</a-button
                > -->
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="section_cont" id="companyProfileCon">
      <div class="c-w1200">
        <h3>为什么选择我们</h3>
        <h6>针对企业六大痛点逐个击破</h6>

        <div class="spot_cont">
          <div
            class="Pain-spot"
            v-for="(item, index) in painSpotList"
            :key="'pain_' + index"
          >
            <img :src="item.path" alt="" />
            <h4>{{ item.title }}</h4>
            <p>{{ item.desc }}</p>
            <p>{{ item.desc2 }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section_cont" id="aboutCon">
      <div class="c-w1200">
        <h3>科技创新 走向智能</h3>
        <div class="support_cont">
          <h2>
            优数科技是一家行业数据分析和组织智能服务平台，致力于通过大数据分析挖掘和认知智能技术，推动知识和管理大中小型企业进行数字化转型。
          </h2>
          <h2>
            整合大数据、区块链、物联网、知识图谱和多模态人工智能技术，将正确的数据推送给决策者，提高组织内营销和运营的透明、安全、稳定。让组织高效运转、加速创新，实现人机同行的美好世界
          </h2>
          <ol>
            <li v-for="(itewm, index) in supportList" :key="'support_' + index">
              <h4>{{ itewm.name }}</h4>
              <p class="telephone">{{ itewm.telephone }}</p>
              <p v-if="itewm.email">{{ itewm.email }}</p>
              <p v-if="itewm.address">{{ itewm.address }}</p>
            </li>
          </ol>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
import "swiper/swiper-bundle.css";
export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      painSpotList: [
        {
          path: require("@/assets/img/icon_01.png"),
          title: "招人难招人贵",
          desc: "劳动密集型行业离职率高",
          desc2: "靠传统招聘不仅慢而且招聘难",
        },
        {
          path: require("@/assets/img/icon_02.png"),
          title: "人员流动大",
          desc: "员工入职身份未经验证，低龄、",
          desc2: "超龄、违法人员上岗时有发生",
        },
        {
          path: require("@/assets/img/icon_03.png"),
          title: "流程不规范",
          desc: "流程仅停留在书面文件",
          desc2: "无法通过系统固化",
        },
        {
          path: require("@/assets/img/icon_04.png"),
          title: "考勤点分散",
          desc: "纸制考勤耗费大量人力物力",
          desc2: "人员出勤状况无法实时掌握",
        },
        {
          path: require("@/assets/img/icon_05.png"),
          title: "工资计算复杂",
          desc: "EXCLE表算薪，效率低下",
          desc2: "易出错、易误发工资",
        },
        {
          path: require("@/assets/img/icon_06.png"),
          title: "信息化程度低",
          desc: "公司通告，公司最新动态，公告信息",
          desc2: "无法及时传达各个层级",
        },
      ],
      supportList: [
        {
          name: "优数科技北京总部",
          telephone: "17601091205",
          address: "北京市东城区龙潭街道东玖大厦B座",
        },
        {
          name: "客服支持",
          telephone: "13811732050",
          email: "kefu@ushuy.com",
        },
        {
          name: "商务合作",
          telephone: "13811732050",
          email: "cooperation@ushuy.com",
        },
      ],
      customerList: [
        {
          id: 1,
          name: "北京都杰安防科技有限公司",
          desc: "都杰先后被公安部评定为人力防范保安服务公司“壹级”企业，被商务部国际贸易经济合作研究院评定为AAA企业，是2019年度北京商务服务业创新和国际化品牌百强企业。",
        },
        {
          id: 2,
          name: "北京京诚京安保安服务有限公司",
          desc: "北京京诚京安保安服务有限公司，前身是北京振远护卫中心直属支队。公司实施改制于2010年10月份作为北京市公安局首批十佳标杆保安企业之一；专业从事保安、安检服务。",
        },
        {
          id: 3,
          name: "中城保安（北京）有限公司",
          desc: "中城保安（北京）有限公司前身系中军军弘安保集团北京分公司。由职业经理人、退转军警和行业专家组成，培养多名保安职业经理人和保安管理师。合作单位300余家，安保人员近1000人。",
        },
        {
          id: 4,
          name: "北京老兵京保保安服务有限公司",
          desc: "北京老兵京保保安服务有限公司成立于2004年06月10日，注册地位于北京市顺义区仁和地区顺通路38号。在公司发展壮大的18年里，我们始终为客户提供好的产品和技术支持、健全的售后服务，公司主要经营门卫、巡逻、守护；劳务派遣；安全防范技术服务；技术培训、技术咨询、技术开发。",
        },
        {
          id: 5,
          name: "卫华(北京)保安服务有限公司",
          desc: "卫华(北京)保安服务有限公司成立于2021年08月25日，注册地位于北京市顺义区林河南大街33号。公司主要经营包括门卫、巡逻、守护;劳务分包;机动车公共停车场管理;物业管理;绿化管理;建筑物清洁服务;专业保洁服务;会议服务;组织文化艺术交流活动。",
        },
      ],
      activeSlide: 0,
      scrollTop: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
      new swiper(".swiper-container", {
        slidesPerView: "auto",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  watch: {
    $route: {
      handler(val, oldval) {
        console.log(val); //新路由信息
        console.log(oldval); //老路由信息
        console.log(val.hash.substring(1));
        if (document.getElementById(val.hash.substring(1))) {
          document.getElementById(val.hash.substring(1)).scrollIntoView({
            behavior: "smooth", //平滑滚动
          });
        }
        if (this.scrollTop == 0) {
          this.$router.push('/')
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    gotoDetails(Id) {
      this.$router.push("/customer/" + Id);
    },
    gotoProductDetails(Id) {
      this.$router.push("/product/" + Id);
    },
    scrollEvent($event) {
      console.log($event);
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
  },
  mounted() {
    let moreDivOffsetTop = document.getElementById("more").offsetTop;
    this.moreDivOffsetTop = moreDivOffsetTop;
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
@import url("../assets/css/common.css");

.section_cont:nth-child(even) {
  background: #fff;
}
.section_cont:nth-child(odd) {
  background: #f7f9fc;
}
/* 基础描述 */
.swiper_cont {
  background: url("../assets/img/video.mp4") no-repeat !important;
  background-size: 100% !important;
}
.videoContainer {
  position: absolute;
  width: 100%;
  height: 565px;
  overflow: hidden;
  z-index: -100;
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  bottom: 0;
  left: 0;
  background: rgba(25, 29, 34, 1);
}
video {
  position: absolute;
  width: 100%;
  display: block;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
}

.swiper_text {
  padding-top: 245px;
  padding-bottom: 200px;
}
.swiper_title {
  font-size: 48px;
  color: #fff;
}
.swiper_title:nth-child(1) {
  padding-bottom: 20px;
}
.section_cont h3 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 25px;
}
.section_cont h6 {
  font-size: 16px;
  text-align: center;
}
.desc_cont {
  padding-top: 70px;
  padding-bottom: 200px;
}
.desc_cont p {
  font-size: 18px;
  text-align: center;
  padding-bottom: 15px;
}
/* 核心 */
.core_cont {
  padding-top: 95px;
  padding-bottom: 150px;
}
.core_cont dl {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-bottom: 60px;
}
.core_cont dl dt {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.core_cont dl dt img {
  height: 100px;
}
.core_cont dl dd {
  width: 80%;
  position: relative;
}
.core_cont dl dd h4 {
  font-size: 22px;
  color: #555555;
  margin-bottom: 30px;
  line-height: 22px;
}
.core_cont dl dd h5 {
  font-size: 16px;
  color: #555555;
  line-height: 35px;
}
.core_btn {
  position: absolute;
  right: 0;
}
.core_btn .ant-btn-primary {
  background: #3f72fd;
}
/* 更多 */
.more_cont {
  padding-top: 80px;
  padding-bottom: 100px;
}
.more_cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8%;
  margin-bottom: 40px;
}
.more_cont ul li {
  width: 30%;
  padding: 4%;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.more_cont ul li h4 {
  font-size: 22px;
  color: #555555;
}
.more_cont ul li img {
  width: 45px;
  height: 45px;
  margin-bottom: 30px;
}

/* 客户案例 */
.case_cont {
  padding-top: 90px;
  padding-bottom: 100px;
  overflow: hidden;
}
.case_cont:deep(.swiper-wrapper) {
  z-index: 0 !important;
  width: auto !important;
}

.case_cont .swiper-container {
  position: unset !important;
}
.case_cont .swiper-container .swiper-slide {
  width: 31.33% !important;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  float: left;
  padding: 4% 2% 3% 2%;
  box-sizing: border-box;
  margin-right: 2%;
}
.case_cont .swiper-container .swiper-slide h4 {
  font-size: 18px;
  color: #555555;
  padding-bottom: 20px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.case_cont .swiper-container .swiper-slide p {
  height: 155px;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  /*autoprefixer: off;*/
  /*autoprefixer: on;*/
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
/* 痛点 */
.spot_cont {
  padding: 90px 80px 0 80px;
  overflow: hidden;
}
.Pain-spot {
  width: 33%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.Pain-spot img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.Pain-spot h4 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}
.Pain-spot p {
  font-size: 16px;
  color: #555555;
  line-height: 25px;
}
/* 科技技术支持 */
.support_cont {
  padding-top: 90px;
  padding-bottom: 130px;
  overflow: hidden;
}
.support_cont h2 {
  font-size: 22px;
  padding-bottom: 35px;
  line-height: 30px;
}
.support_cont ol {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.support_cont ol li {
  width: 32%;
  background: #ffffff;
  border-radius: 10px;
  padding: 4% 3% 4% 3%;
}
.support_cont ol li h4 {
  font-size: 22px;
  color: #555555;
  padding-bottom: 28px;
}
.support_cont ol li p {
  font-size: 16px;
  color: #333333;
}
.support_cont ol li p.telephone {
  padding-bottom: 20px;
}

:deep(.swiper-pagination) {
  bottom: 40px !important;
  height: 10px !important;
  width: 100% !important;
  z-index: 0 !important;
}

.swiper-pagination :deep(.swiper-pagination-bullet) {
  width: 30px !important;
  height: 10px !important;
  background: rgba(63, 114, 253, 0.3) !important;
  border-radius: 3px !important;
  margin-left: 10px !important;
}

.swiper-pagination :deep(.swiper-pagination-bullet-active) {
  width: 30px !important;
  height: 10px !important;
  background: #3f72fd !important;
  border-radius: 3px !important;
  margin-left: 10px !important;
}
</style>
