import {
    createRouter,
    createWebHistory
} from "vue-router";
import Home from '../pages/home.vue';
import Product from '../pages/product.vue';
import Customer from '../pages/customer.vue';
const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: '优数科技 - 优数科技助力安保行业提升服务能力',
                keywords: '优数科技,优数合一,优数科技官网,优数安保通,优数安保易,优数云,安保通,安保易,安保通小程序,安保易SaaS平台,移动办公,移动考勤,移动办公平台,移动办公app,移动办公解决方案',
                description: '优数科技是一家行业数据分析和组织智能服务平台，致力于通过大数据分析挖掘和认知智能技术，推动知识和管理大中小型企业进行数字化转型。整合大数据、区块链、物联网、知识图谱和多模态人工智能技术，将正确的数据推送给决策者，提高组织内营销和运营的透明、安全、稳定。让组织高效运转、加速创新，实现人机同行的美好世界'
            },
        },
        {
            path: '/product/:id',
            name: 'product',
            component: Product,
            meta: {
                title: '优数科技 - 优数科技助力安保行业提升服务能力',
                keywords: '优数科技,优数合一,优数科技官网,优数安保通,优数安保易,优数云,安保通,安保易,安保通小程序,安保易SaaS平台,移动办公,移动考勤,移动办公平台,移动办公app,移动办公解决方案',
                description: '优数科技是一家行业数据分析和组织智能服务平台，致力于通过大数据分析挖掘和认知智能技术，推动知识和管理大中小型企业进行数字化转型。整合大数据、区块链、物联网、知识图谱和多模态人工智能技术，将正确的数据推送给决策者，提高组织内营销和运营的透明、安全、稳定。让组织高效运转、加速创新，实现人机同行的美好世界'
            },
        },
        {
            path: '/customer/:id',
            name: 'customer',
            component: Customer,
            meta: {
                title: '优数科技 - 优数科技助力安保行业提升服务能力',
                keywords: '优数科技,优数合一,优数科技官网,优数安保通,优数安保易,优数云,安保通,安保易,安保通小程序,安保易SaaS平台,移动办公,移动考勤,移动办公平台,移动办公app,移动办公解决方案',
                description: '优数科技是一家行业数据分析和组织智能服务平台，致力于通过大数据分析挖掘和认知智能技术，推动知识和管理大中小型企业进行数字化转型。整合大数据、区块链、物联网、知识图谱和多模态人工智能技术，将正确的数据推送给决策者，提高组织内营销和运营的透明、安全、稳定。让组织高效运转、加速创新，实现人机同行的美好世界'
            },
        },
        // {
        //     path:'/companyProfile',
        //     name: 'companyProfile',
        //     component: CompanyProfile
        // },
        // {
        //     path:'/about',
        //     name: 'about',
        //     component: About
        // }
    ]
})


router.beforeEach((to, from, next) => {
    /*********动态修改keywords和description*************/
    if (Object.keys(to.meta).length > 0 && to.matched.length > 0) {
        let this_meta = to.matched[to.matched.length - 1].meta
        if (this_meta.title) document.title = this_meta.title
        let head = document.getElementsByTagName('head');
        let meta_keyword = document.createElement('meta');
        if (document.querySelector('meta[name="keywords"]')) {
            document.querySelector('meta[name="keywords"]').setAttribute('content', this_meta.keywords)
        } else {
            meta_keyword.setAttribute('name', 'keywords')
            meta_keyword.setAttribute('content', this_meta.keywords)
            head[0].appendChild(meta_keyword)
        }
        let meta_description = document.createElement('meta');
        if (document.querySelector('meta[name="description"]')) {
            document.querySelector('meta[name="description"]').setAttribute('content', this_meta.description)
        } else {
            meta_description.setAttribute('name', 'description')
            meta_description.setAttribute('content', this_meta.description)
            head[0].appendChild(meta_description)
        }
    }
    /**********************************************/
    // 需要注意这里不能使用this,
    // 因为我们使用的是进入路由之前，那会组件还没创建，
    next(() => {
        // 所有我们只能使用过vm异步语句来让节点上树；
        console.log(to.hash);
        next();
        document.getElementById(to.hash.substring(1)).scrollIntoView({
            behavior: "smooth", //平滑滚动
        });
    });
})
export default router;