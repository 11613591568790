// vue3 全局注册组件
import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'

// 新增代码：引入特定组件及样式
import {
    Button, Form, Input
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/lib/button/style/css'
import 'ant-design-vue/lib/form/style/css'
import 'ant-design-vue/lib/input/style/css'
import '../src/assets/css/reset.css'

const app = createApp(App)
app.use(Button);
app.use(Form);
app.use(Input);
app.use(router);
app.mount('#app');